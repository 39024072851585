import { createRouter, createWebHistory } from "vue-router";
import userInfoApi from "@/api/userInfo";
import titleMap from "./titleMap";
import request from "../request/Request";
import { appid } from "../tools/config";
let wx = require("weixin-js-sdk");

const routes = [];
titleMap.forEach((item) => {
  let obj = {};
  obj.path = item.path;
  if (!item.children) {
    obj.name = item.name;
  }
  obj.meta = {};
  obj.meta.title = item.title;
  obj.component = () => import(`../views/${item.component}.vue`);
  if (item.children) {
    obj.children = [];
    item.children.forEach((data) => {
      let obj1 = {};
      obj1.path = data.path;
      obj1.name = data.name;
      obj1.meta = {};
      obj1.meta.title = data.title;
      obj1.component = () => import(`../views/${data.component}.vue`);
      obj.children.push(obj1);
    });
  }
  routes.push(obj);
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const setConfig = async (url) => {
  let res = await request.handleFetchPostJson(userInfoApi.signature, {
    url: url,
  });
  if (res.code === 2000) {
    wx.config({
      debug: false,
      appId: appid,
      timestamp: res.timestamp,
      nonceStr: res.noncestr,
      signature: res.signature,
      jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
    });
  }
};

const isIos = () => {
  const u = navigator.userAgent;
  return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
};

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (isIos()) {
    if (from.path === "/") {
      setConfig(window.location.href);
      next();
    } else {
      next();
    }
  } else {
    if (to.name == "hotDetail" || to.name === "inviteShare") {
      setConfig(window.location.origin + to.fullPath);
      next();
    } else {
      next();
    }
  }
});

export default router;
