const titleMap = [
  {
    title: "容器",
    path: "/layout",
    name: "layout",
    component: "Layout",
    children: [
      {
        title: "基因云",
        path: "/layout/home",
        name: "home",
        component: "Home",
      },
      {
        title: "云课堂",
        path: "/layout/class",
        name: "class",
        component: "Class",
      },
      {
        title: "我的",
        path: "/layout/personal",
        name: "personal",
        component: "Personal",
      },
    ],
  },
  {
    title: "云课堂",
    path: "/hotDetail",
    name: "hotDetail",
    component: "ClassHotDetail",
  },
  {
    title: "观看历史",
    path: "/history",
    name: "history",
    component: "History",
  },
  {
    title: "我的收藏",
    path: "/myCollection",
    name: "myCollection",
    component: "MyCollection",
  },
  {
    title: "登录",
    path: "/login",
    name: "login",
    component: "Login",
  },
  {
    title: "注册",
    path: "/register",
    name: "register",
    component: "Register",
  },
  {
    title: "登录",
    path: "/authBefore",
    name: "authBefore",
    component: "AuthBefore",
  },
  {
    title: "搜索",
    path: "/search",
    name: "search",
    component: "Search",
  },
  {
    title: "基因云",
    path: "/",
    name: "auth",
    component: "Auth",
  },
  {
    title: "用户服务协议",
    path: "/userAgreement",
    name: "userAgreement",
    component: "UserAgreement",
  },
  {
    title: "我的云贝",
    path: "/signList",
    name: "signList",
    component: "SignList",
  },
  {
    title: "邀请有礼",
    path: `/inviteShare/:inviteCode?`,
    name: "inviteShare",
    component: "InviteShare",
  },
];

export default titleMap;
