import axios from "axios";
import settle from "axios/lib/core/settle";
import $cookie from "../tools/cookieMethods";
import router from "../router";
import { Toast } from "vant";
import { api_dev, api_pro } from "../tools/config";

const qs = require("querystring");
const baseURL = process.env.NODE_ENV === "development" ? api_dev : api_pro;
const instance = axios.create({
  baseURL,
  headers: {},
});
const cacheMap = new Map();
const wrapperAdapter = (adapter) => (config) => {
  const cache = config.cache || false;
  if (cache && cacheMap.has(config.url)) {
    return new Promise((resolve, reject) => {
      settle(resolve, reject, cacheMap.get(config.url));
    });
  }
  return adapter(config);
};

instance.interceptors.request.use(
  (config) => {
    config.params = { ...config.params };
    if ($cookie.getCookie("tokenid") !== "") {
      config.headers["Authorization"] =
        "Bearer " + $cookie.getCookie("tokenid");
    }
    if (
      config.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      config.data = qs.stringify(config.data);
    }
    config.adapter = wrapperAdapter(config.adapter);
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    if (response.config.cache) {
      const cacheKey = response.config.url;
      cacheMap.set(cacheKey, response);
    }
    return response;
  },
  () => {
    router.replace({
      name: "authBefore",
    });
    Toast.clear();
    Toast("网络错误");
  }
);

export default {
  post(...options) {
    return instance.post(...options);
  },
  get(...options) {
    return instance.get(...options);
  },
  put(...options) {
    return instance.put(...options);
  },
};
