import { isMobile } from "@/tools/common";
export default function (doc, win) {
  const docEl = doc.documentElement;
  const resizeEvt =
    "orientationchange" in window ? "orientationchange" : "resize";
  function recalc() {
    // const { clientWidth } = docEl;
    let clientWidth = docEl.clientWidth;
    if (!clientWidth) return;

    if (!isMobile) {
      if (clientWidth >= 550) {
        clientWidth = 550;
      }
    }

    docEl.style.fontSize = 100 * (clientWidth / 375) + "px";

    // if (clientWidth >= 375) {
    //   docEl.style.fontSize = "100px";
    // } else {
    //   docEl.style.fontSize = `${100 * (clientWidth / 375)}px`;
    // }
  }

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener("DOMContentLoaded", recalc, false);
}
