import { Toast } from "vant";
import axios from "./Axios";
import router from "../router";
import { checkMsgList } from "@/tools/responseMsg";

function handleFetchGet(url, dataParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, dataParams)
      .then((data) => {
        if (data.data.code == 2000) {
          resolve(data.data);
        } else {
          if (data.data.message) {
            if (data.data.message == "需要重新授权") {
              router.replace({
                name: "authBefore",
              });
            } else if (checkMsgList.indexOf(data.data.message) !== -1) {
              resolve(data.data);
              return;
            }
            Toast(data.data.message);
          } else {
            Toast("网络错误");
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function handleFetchPost(url, dataParams) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, dataParams, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((data) => {
        if (data.data.code == 2000) {
          resolve(data.data);
        } else {
          Toast.clear();
          if (data.data.message) {
            Toast(data.data.message);
            router.replace({
              name: "authBefore",
            });
          } else {
            Toast("网络错误");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function handleFetchPostJson(url, dataParams) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, dataParams, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.data.code == 2000) {
          resolve(data.data);
        } else {
          Toast.clear();
          if (data.data.message) {
            Toast(data.data.message);
            router.replace({
              name: "authBefore",
            });
          } else {
            Toast("网络错误");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function handleFetchPut(url, dataParams) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, dataParams)
      .then((data) => {
        if (data.data.code == 2000) {
          resolve(data.data);
        } else {
          if (data.data.message) {
            Toast(data.data.message);
          } else {
            Toast("网络错误");
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  handleFetchGet,
  handleFetchPost,
  handleFetchPostJson,
  handleFetchPut,
};
