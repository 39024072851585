import personalApi from "@/api/personal";
import $cookie from "@/tools/cookieMethods";
import handleFetch from "@/request/Request";
export default {
  modifyIndexAsync({ commit }, params) {
    commit("modifyIndex", params);
  },

  // 获取云平台用户标识、用户名、头像
  async getUserInfoAction({ commit }, params) {
    const res = await handleFetch.handleFetchGet(personalApi.userindex);
    let userObj = res.code === 2000 ? res : {};
    $cookie.setCookie("userInfo", JSON.stringify(userObj), 25, "/");
    commit("getUserInfoMutation", userObj);
    return res;
  },
};
