/**
 * 获取用户信息类api：注册、登录、授权、用户信息等接口
 */
export default {
  mcode_login: "/api/v1/miniprog/mcode_login", //
  mess_code: "/api/v1/user/mess_code/", //
  telunique: "/api/v1/user/telunique/", //
  userunique: "/api/v1/user/userunique/", //
  register: "/api/v1/user/register/", // 注册
  membership_register: "/api/v1/membership/register/", // 带有邀请码注册
  // userindex: "user/userindex/", // 获取用户信息
  unionid_check: "/api/v1/miniprog/unionid_check", // 检验unionid
  authorization: "/api/v1/miniprog/authorization", // 判断有木有微信授权
  signature: "/api/v1/miniprog/signature", // 获取微信签名
};
