const wx = require("weixin-js-sdk");

/**
 * Check whether it is a mobile terminal
 * @returns {boolean} True if value is a Mobile, otherwise PC
 */
const isMobile = (() => {
  if (
    window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true; // 移动端
  } else {
    return false; // PC端
  }
})();

// Generate a UUID for use during registration
const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";
  var uuid = s.join("");
  return uuid;
};

// wechat share to friends or circle of friends
const friendsCircle = (title, desc, link, img) => {
  // 微信分享
  wx.ready(function () {
    wx.checkJsApi({
      jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
    });
    wx.updateAppMessageShareData({
      link: link,
      title: title,
      desc: desc,
      imgUrl: img,
      success: function () {},
    });
    wx.updateTimelineShareData({
      link: link,
      title: title,
      imgUrl: img,
      success: function () {},
    });
  });
};

export { uuid, isMobile, friendsCircle };
