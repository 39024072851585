import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import request from "./request/Request";
import cookie from "./tools/cookieMethods";
import VideoPlayer from "vue-video-player/src";
import remPhoneConfig from "./tools/remConfig";
import "vant/lib/index.css";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "./sheet/index.scss";
const app = createApp(App);
app.config.globalProperties.$request = request;
app.config.globalProperties.$cookie = cookie;
remPhoneConfig(document, window);
app.use(store).use(router).use(Vant).use(VideoPlayer).mount("#app");
