const api_v1 = "/api/v1/";
const appid = "wx270e503804798f0f";
const api_dev = "http://47.103.176.40:9999";
const api_pro = "https://wx.genescloud.cn";
const clickList = [
  {
    key: "first",
    name: "产品类型",
    children: [
      {
        name: "微生物",
        value: "微生物群落多样性组成谱分析",
        active: false,
      },
      {
        name: "转录组",
        value: "真核有参转录组分析",
        active: false,
      },
    ],
  },
  {
    key: "second",
    name: "课程类型",
    children: [
      { name: "云端实战", value: "云端实战", active: false },
      { name: "分析解读", value: "分析解读", active: false },
      { name: "案例解析", value: "案例解析", active: false },
      { name: "QIME2", value: "QIME2", active: false },
    ],
  },
  {
    key: "third",
    name: "付费类型",
    children: [
      { name: "付费", value: false, active: false },
      { name: "免费", value: true, active: false },
    ],
  },
];

const swipe_list = [
  {
    surface: "https://genescloud.cn/web/images/banner/mobile/1.png",
    video_id: "6c9ddcaa7a5347b99aca50170518e421",
  },
  {
    surface: "https://genescloud.cn/web/images/banner/mobile/2.png",
    video_id: "f3386aafde2d461983877223163ad547",
  },
  {
    surface: "https://genescloud.cn/web/images/banner/mobile/3.png",
    video_id: "a00144f0cd1c47a7aef8e5602e498edb",
  },
];

export { api_v1, appid, api_dev, api_pro, clickList, swipe_list };
