import { createStore, createLogger } from "vuex";
import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getter from "./getter";
import modules from "./modules";
import request from "../request/Request";

let logger;
if (process.env.NODE_ENV !== "development") {
  logger = [];
} else {
  logger = [createLogger()];
}

export default createStore({
  plugins: logger,
  state: {
    request,
    ...state
  },
  mutations,
  actions,
  modules,
  getter
});
