export default {
  userinfo: "/api/v1/miniprog/userinfo", // 获取用户头像等信息

  // 历史记录页
  his_list: "/api/v1/college/his_list/", // 获取历史视频
  del_his: "/api/v1/college/del_his/", // 删除历史视频

  // 我的收藏
  col_list: "/api/v1/college/col_list/", // 视频收藏
  del_col: "/api/v1/college/del_col/", // 删除收藏视频

  // 我的云贝
  yb_record: "/api/v1/user/yb_record/", // user/yb_record/{cur_page}云贝记录列表
  userindex: "/api/v1/user/userindex/", //

  // 邀请页
  checkcode: "/api/v1/membership/checkcode/", // 验证邀请码
};
