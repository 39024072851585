function setCookie(c_name, value, expiredays, path) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString()) +
    `${path ? ";path=" + path : ""}`;
}

function setCookieTimes(c_name, value, expireseconds) {
  var exdate = new Date();
  exdate.setTime(exdate.getTime() + expireseconds * 1000);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expireseconds == null ? "" : ";expires=" + exdate.toGMTString());
}

function getCookie(userName) {
  let c_start, c_end;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(userName + "=");
    if (c_start != -1) {
      c_start = c_start + userName.length + 1;
      c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

function delCookie($name) {
  var myDate = new Date();
  myDate.setTime(-1000);
  document.cookie = $name + "=''; expires=" + myDate.toGMTString();
}

export default { setCookie, getCookie, setCookieTimes, delCookie };
